import './stylesheets/landing.scss'
import './images/tech-radar-landing-page-wide.png'
import './images/favicon.ico'
import './images/social/Github.svg'
import './images/social/Instagram.svg'
import './images/social/Linkedin.svg'
import './images/social/Twitter.svg'
import './images/cert-logos/aicpa.svg'
import './images/cert-logos/gdpr.svg'
import './images/cert-logos/hipaa.svg'
import './images/cert-logos/iso.svg'
require('./common')
require('./images/logo.png')
require('./images/radar_legend.png')
require('./images/more-background.png')
require('./images/underline-stroke.svg')
require('./gtm.js')

export const InitializeApp = () => {
  const pageUtils = require('./util/pageUtils')
  const pageContent = require('./util/pageContent')
  const GoogleSheetInput = require('./util/factory')
  const BubbleChartFactory = require('./util/bubble-factory')
  const main = document.getElementsByTagName('main')[0]
  const mainContent = main.innerHTML

  pageContent.getPageContent()

  GoogleSheetInput().build()
  BubbleChartFactory.buildCharts()

  function resizedw() {
    clear()
    GoogleSheetInput().build()
  }

  var doit
  window.onresize = function () {
    clearTimeout(doit)
    doit = setTimeout(resizedw, 200)
  }

  function clear() {
    main.innerHTML = mainContent
  }

  pageUtils.setToggleDarkMode()

  pageUtils.activeMenuOnScroll()

  pageUtils.hamburgerMenuClick()
}
