const d3 = require('d3');

const url = process.env.RADAR_API;

const BubbleNodeID = {
    Platforms: "platforms-bubble-container",
    Services: "services-bubble-container",
    FrameworksAndTools: "frameworks-bubble-container",
    Languages: "languages-bubble-container",
}

let bubbleChartsLoaded = 0;
const bubbleChartsCount = 4;
const bubbleCount = 10;

const buildChart = (nodeID, dataset) => {
    document.getElementById(nodeID).innerHTML = '';
    var diameter = 500;
    var color = "white";
    let bubbleColor = nodeID == BubbleNodeID.Languages ? "#6AD2D2" : nodeID == BubbleNodeID.Services ? "#FC530C" : nodeID == BubbleNodeID.Platforms ? "#F8D74F" : "#83DEA8";

    var bubble = d3.pack(dataset)
        .size([diameter, diameter])
        .padding(1.5);
    let margin = 10;
    // By using this scaling factor of 2, the viewBox accommodates the extra space required for the margin around the circle layout
    let expandedSize = diameter + 2 * margin;
    var svg = d3.select(`#${nodeID}`)
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `-${margin} -${margin} ${expandedSize} ${expandedSize}`)
        .classed("bubbles", true)
        .attr("id", `bubble-${nodeID}`);

    var circles = d3.hierarchy(dataset)
        .sum(function (d) { return d.count; });

    var circle = svg.selectAll(".circle")
        .data(bubble(circles).descendants())
        .enter()
        .filter(function (d) {
            return !d.children
        })
        .append("g")
        .attr("class", "circle")
        .attr("transform", function (d) {
            return "translate(" + d.x + "," + d.y + ")";
        });

    circle.append("circle")
        .attr("r", function (d) {
            return d.r;
        })
        .style("fill", function () {
            return color;
        })
        .style("stroke", function () {
            return bubbleColor;
        })
        .style("stroke-width", function () {
            return "1em";
        });

    circle.append("text")
        .attr("dy", ".2em")
        .style("text-anchor", "middle")
        .text(function (d) {
            const MAX_LENGTH = 17;
            return d.data.name.length > MAX_LENGTH
             ? d.data.name.substr(0, MAX_LENGTH).concat('...')
             : d.data.name.substr(0, MAX_LENGTH);

        })
        .attr("font-family", "Poppins")
        .attr("font-size", function (d) {
            let labelLength = d.data.name.length;
            if (d.data.name.length > 17) {
                labelLength = 24;
            }
            const value = d.r < 90 ? Number.parseInt(d.r) : 90
            const ratio = Number.parseInt(value / Number.parseInt(labelLength));
            const MIN_DIFF = 4;
            const MAX_DIFF = 5.5;
            return ratio < MIN_DIFF
                ? value / MAX_DIFF
                : value / MIN_DIFF;
        })
        .attr("fill", "black");

    var bubblesSvg = document.getElementById(`bubble-${nodeID}`);

    bubblesSvg.addEventListener('load', function(){
        bubbleChartsLoaded++;

        if(bubbleChartsLoaded == bubbleChartsCount) {
            removeLoadingSpinner();
        }
    });

}

function removeLoadingSpinner() {
    document.querySelector('.bubble-charts').style.display = 'block';
    document.querySelector('.loader').style.display = 'none';
}

function displayErrorMessage() {
    d3.select('.bubble-charts').selectAll(".row").remove();
    d3.select('.bubble-charts').append('div').attr("class", "error-message").text("There was an error retrieving bubble charts.");
    removeLoadingSpinner();
}

export const buildCharts = () => {
    fetch(`${url}/api/bubble`)
        .then(response => response.json())
        .then(result => {
            buildChart(BubbleNodeID.Languages, { children: result.filter(i=>i.category == "Languages").slice(0, bubbleCount) });
            buildChart(BubbleNodeID.FrameworksAndTools, { children: result.filter(i=>i.category == "Frameworks and Tools").slice(0, bubbleCount) });
            buildChart(BubbleNodeID.Services, { children: result.filter(i=>i.category == "Services").slice(0, bubbleCount) });
            buildChart(BubbleNodeID.Platforms, { children: result.filter(i=>i.category == "Platforms").slice(0, bubbleCount) });
        })
        .then(()=> removeLoadingSpinner())
        .catch(() => displayErrorMessage());
}
