export function setToggleDarkMode() {
  const btn = document.querySelector('#flexSwitchCheckDefault');

  btn.addEventListener('click', function () {
    // Dark theme class toggle
    const toggleDarkTheme = (query) => {
      const selected = document.querySelectorAll(query)
      selected.forEach(element => {
        element.classList.toggle('dark-theme')
      })
    }

    // Body 
    toggleDarkTheme('body');

    // Header
    toggleDarkTheme('.header-summary .card')

    // Footer
    toggleDarkTheme('footer .footer-content');
    toggleDarkTheme('footer .section-title');
    toggleDarkTheme('footer .contact-text');
    toggleDarkTheme('footer .border-word');
    toggleDarkTheme('footer .docs-link');
    toggleDarkTheme('footer .bottom-section');
    toggleDarkTheme('footer .location-text');
  })
}

export function hamburgerMenuClick() {
  const btn = document.querySelector('.ham-button');

  btn.addEventListener('click', function () {
    const nav = document.querySelector('nav');
    nav.classList.toggle('is-visible');
  })
}

export function activeMenuOnScroll() {
  const links = document.querySelectorAll('.navbar-item');
  const sections = document.querySelectorAll('.page-section');
  const subLinks = document.querySelectorAll('.navbar-sub-item');
  const subSections = document.querySelectorAll('.page-sub-section');

  function changeLinkState(sections, links) {
    let index = sections.length;

    // decrement the index with each iteration while 
    while (--index && window.scrollY + 50 < sections[index].offsetTop) { }

    links.forEach((link) => link.classList.remove('active'));

    // add the active class if within visible height of the element
    if (isInViewport(sections[index])) {
      links[index].classList.add('active');
    }
  }

  function isInViewport(elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  function checkSection() {
    changeLinkState(sections, links);

    if (links[2].classList.contains('active')) {
      changeLinkState(subSections, subLinks);
    }
  }

  window.addEventListener('scroll', checkSection);
}