const msalConfig = {
  auth: {
    clientId: process.env.CLIENT_ID,
    authority: process.env.AUTHORITY,
    redirectUri: process.env.REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
}

module.exports = msalConfig
