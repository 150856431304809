import file from '../../public/page-content.yml'

let content = file;

export const getPageContent = () => {
    try {
        fillPage();
    } catch (error) {
        displayPageLoadingError(error);
    }
}

function fillPage() {
    headerContent();
    overviewContent();
    insightsContent();
    radarLevelsContent();
    moreAboutContent();
    navBarContent();
    applyStyle();
}

function applyStyle() {
    document.querySelector('.page-load').style.display = 'none';
    document.body.style.overflow = 'auto';
}

function displayPageLoadingError(error) {
    console.log('error', error);
    document.querySelector('.page-loader').style.display = 'none';
    document.querySelector('.loading-error').style.display = 'block';
}

function headerContent() {
    document.getElementById('header-title').innerHTML = content['header']['title'];
    document.getElementById('header-subtitle').innerHTML = content['header']['subtitle'];
    document.getElementById('header-text').innerHTML = content['header']['text'];
}

function overviewContent() {
    document.getElementById('overview-title').innerHTML = content['overview']['title'];
    document.getElementById('overview-text').innerHTML = content['overview']['text'];
}

function insightsContent() {
    document.getElementById('insights-title').innerHTML = content['insights']['title'];
    document.getElementById('insights-subtitle').innerHTML = content['insights']['subtitle'];

    for (let i = 1; i <= 4; i++) {
        document.getElementById(`card${i}-title`).innerHTML = content['insights']['cards'][`card${i}`]['title'];
        document.getElementById(`card${i}-text`).innerHTML = content['insights']['cards'][`card${i}`]['text'];

        document.getElementById(`num${i}-title`).innerHTML = content['insights']['numerics'][`num${i}`]['title'];
        document.getElementById(`num${i}-number`).innerHTML = content['insights']['numerics'][`num${i}`]['number'];
        document.getElementById(`num${i}-subtitle`).innerHTML = content['insights']['numerics'][`num${i}`]['subtitle'];
    }
}

function radarLevelsContent() {
    document.getElementById(`rings-title`).innerHTML = content['radar-levels']['title'];

    for (let i = 1; i <= 4; i++) {
        document.getElementById(`ring${i}-title`).innerHTML = content['radar-levels']['radar-rings'][`ring${i}`]['title'];
        document.getElementById(`ring${i}-text`).innerHTML = content['radar-levels']['radar-rings'][`ring${i}`]['text'];
    }
}

function moreAboutContent() {
    document.getElementById(`more-title`).innerHTML = content['more-about-sw']['title'];
    document.getElementById(`more-title-underlined`).innerHTML = content['more-about-sw']['underline'];

    document.getElementById(`more-card4-title`).innerHTML = content['more-about-sw'][`card4`]['title'];
    document.getElementById(`more-card4-button`).innerHTML = content['more-about-sw'][`card4`]['button-text'];

    for (let i = 1; i <= 3; i++) {
        document.getElementById(`more-card${i}-title`).innerHTML = content['more-about-sw'][`card${i}`]['title'];
        document.getElementById(`more-card${i}-text`).innerHTML = content['more-about-sw'][`card${i}`]['text'];
        document.getElementById(`more-card${i}-button`).innerHTML = content['more-about-sw'][`card${i}`]['button-text'];
    }
}

function navBarContent() {
    document.getElementById('nav-overview-item').innerHTML = content['overview']['title'];
    document.getElementById('nav-insights-item').innerHTML = content['insights']['title'];
    document.getElementById(`nav-insights-languages-item`).innerHTML = content['insights']['cards'][`card1`]['title'];
    document.getElementById(`nav-insights-frameworks-item`).innerHTML = content['insights']['cards'][`card2`]['title'];
    document.getElementById(`nav-insights-services-item`).innerHTML = content['insights']['cards'][`card3`]['title'];
    document.getElementById(`nav-insights-platforms-item`).innerHTML = content['insights']['cards'][`card4`]['title'];
    document.getElementById(`nav-more-about-item`).innerHTML = content['more-about-sw']['title'] + "<br>" + content['more-about-sw']['underline'];
}