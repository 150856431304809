const msalConfig = require('./auth/authConfig.js')
const { PublicClientApplication } = require('@azure/msal-browser')
const site = require('./siteContent.js')

const msalApp = new PublicClientApplication(msalConfig)
const loginRequest = {
  scopes: ['User.Read'],
}

function selectAccount() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  const currentAccounts = msalApp.getAllAccounts()

  if (currentAccounts.length === 0) {
    msalApp.loginRedirect(loginRequest)
  } else {
    site.InitializeApp()
  }
}

function handleResponse(response) {
  if (response !== null) {
    site.InitializeApp()
  } else {
    selectAccount()
  }
}

/**
 * A promise handler needs to be registered for handling the
 * response returned from redirect flow. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */
msalApp
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.error(error)
  })
